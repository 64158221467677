/* styles/globals.css */
html, body, #__next {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.checkmark {
  color: #45B6AF;
}